import uuidv1 from 'uuid/v1';

const headers = {
    Timestamp: new Date().toISOString(),
    Channel: 'Channel.Web',
    'Caller-Id': 'Channel.Web',
    'Device-Info': btoa(
        JSON.stringify({
            device_name: window.navigator.userAgent,
            device_os: '',
            device_id: '',
            sim_info: [],
            mac: '',
            geolocation: '',
        }),
    ),
};

let envTokenHeaders = {
    ...headers,
    'Content-Type': 'application/x-www-form-urlencoded',
};

let envRegisteredTokenHeaders = {
    ...headers,
    'Content-Type': 'application/x-www-form-urlencoded',
};

let envTimeTokenHeaders = {};

envTokenHeaders = {
    ...envTokenHeaders,
    Authorization: `Basic ${btoa(process.env.REACT_APP_TOKEN_HEADERS)}`,
};
envRegisteredTokenHeaders = {
    ...envRegisteredTokenHeaders,
    Authorization: `Basic ${btoa(process.env.REACT_APP_REGISTERED_TOKEN_HEADERS)}`,
};

envTimeTokenHeaders = {
    ...envTokenHeaders,
    Authorization: `Basic ${btoa(process.env.REACT_APP_TIME_TOKEN_HEADERS)}`,
};

const BUILD_ID = process.env.REACT_APP_BUILD_ID || 'local';

export const Config = {
    environment: process.env.REACT_APP_ENVIRONMENT,
    appVersion: `${process.env.REACT_APP_VERSION}-${BUILD_ID}`,
    proxy: process.env.REACT_APP_PROXY,
    tokenHeaders: envTokenHeaders,
    registeredTokenHeaders: envRegisteredTokenHeaders,
    timeTokenHeaders: envTimeTokenHeaders,
    tokenBody: {
        grant_type: 'client_credentials',
        scope: 'device_not_authorized',
    },
    registeredTokenBody: {
        grant_type: 'client_credentials',
    },
    rootUrl: process.env.REACT_APP_API,
    headers: {
        ...headers,
        'Content-Type': 'application/json',
        'Session-Id': uuidv1(),
    },
    otp: {
        timer: 120,
        numberOfInput: 4,
        maxResend: 3,
        maxInput: 3,
    },
    cardPan: {
        numberOfInput: 4,
    },
    pin: {
        numberOfInput: 4,
    },
    mcqTimer: 90,
    timeoutTimer: 30,
    timeoutTimerWait: 120,
    personLoan: 'personal-loan',
    rsaPinPublicKey: process.env.REACT_APP_RSA_PIN_PUBLIC_KEY,
    transportRsaPublicKey: process.env.REACT_APP_TRANSPORT_RSA_PUBLIC_KEY,
    payloadPublicKey: process.env.REACT_APP_PAYLOAD_PUBLIC_KEY,
    mobileSalt: process.env.REACT_APP_MOBILE_SALT,
    apiContext: {
        activation: 'acs',
        otp: 'otp',
        time: 'ts',
        reset: 'rsp',
        creditCardOnboarding: 'ccos',
    },
    apiVersion: process.env.REACT_APP_API_VERSION,
    proxyUri: {
        auth: '/iba/auth',
        siteverify: '/rp/siteverify',
        siteverifyIba: '/iba/siteverify',
        logout: '/iba/logout',
    },
    apiUri: {
        otpGenerate: '/otp',
        otpVerify: '/otp/verification/request-token',
        activateActivation: '/activation/activate',
        passwordActivation: '/activation/password',
        time: '/time',
        searchId: '/reset-pin/generate-token',
        mcqs: '/reset-pin/get-id-questions',
        setCredential: '/reset-pin/verifyMCQ-and-setcredential',
        verifyCardPan: '/reset-pin/verify-card',
        setCredentialNew: '/reset-pin/set-credential',
        checkEligibility: '/reset-pin/eligibility',
        verifyMCQ: '/reset-pin/verifyMCQ',
        setCredentialWithMCQ: '/reset-pin/reset-password-with-mcq',
        getMCQ: '/reset-pin/get-mcq-questions',
        creditCardSession: '/credit-card-onboarding/activation/session',
        captureTC: '/credit-card-onboarding/activation/tnc',
        creditCardIdentification: '/credit-card-onboarding/activation/card-identification',
        captureTax: '/credit-card-onboarding/activation/crs',
        creditCardSetCredential: '/credit-card-onboarding/activation/credentials',
        naturePurpose: '/credit-card-onboarding/activation/nature-purposes',
    },
    googleAnalytics: {
        trackingId: process.env.REACT_APP_GA_TRACKING_ID,
        debug: false,
        gaOptions: {
            cookieDomain: 'none',
        },
    },
    googleTagManager: {
        gtmId: process.env.REACT_APP_GA_GTM_ID,
        dataLayerName: 'PageDataLayer',
    },
    googleRecaptcha: {
        sitekey: process.env.REACT_APP_RECAPTCHA_SITEKEY,
    },
    loginUrl: process.env.REACT_APP_IB_LOGIN_URL,
    registerUrl: process.env.REACT_APP_REGISTER_URL,
    resetPasswordUrl: process.env.REACT_APP_RESET_PASSWORD_URL,
    findKioskUrl: 'https://www.tymebank.co.za/banking/where-to-bank/kiosks/',
    pageTitle: {
        enterIdPin: 'Activate Internet Banking | TymeBank',
        password: 'Set password | TymeBank',
        reset: 'Reset your profile credentials | TymeBank',
        enterId: 'Enter your ID number | TymeBank',
        mcqStart: 'Verify your identity | TymeBank',
        mcqSelect: 'Confirm your identity | TymeBank',
        resetPassword: 'Reset password | TymeBank',
        resetPin: 'Reset PIN | TymeBank',
        creditCardActivation: 'Credit Card Activation | TymeBank',
    },
    appRoutes: {
        HOME: '/',
        ACTIVATE: '/password',
        RESET_CREDENTIAL: '/reset-credentials',
        RESET_PIN: '/reset-credentials/resetPin',
        RESET_PASSWORD: '/reset-credentials/resetPassword',
        CC_START : '/profile-activation/credit-card',
        CREDIT_CARD: '/profile-activation/credit-card/setup',
        CREDIT_CARD_SET_PIN_PASSWORD: '/profile-activation/credit-card/pin-code',

        PL_START : '/profile-activation/personal-loan',
        PERSONAL_LOAN: '/profile-activation/personal-loan/setup',
        PERSONAL_LOAN_CODE: '/profile-activation/personal-loan/pin-code',
    },

    privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY,
    termAndConditionsUrl: process.env.REACT_APP_TERM_CONDITIONS,
    appleStoreUrl: process.env.REACT_APP_APPLE_STORE_URL,
    googlePlayUrl: process.env.REACT_APP_GOOGLE_PLAY_URL,
    huaweiUrl: process.env.REACT_APP_HUAWEI_STORE_URL,
    resetPinToggle: process.env.REACT_APP_RESET_PIN
};
